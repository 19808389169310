import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://serv-auth.nabook.co"
    : "http://localhost:3003";

export async function requestSignin(body: { email: string; pwd: string }) {
  try {
    const res = await axios.post(`${BASE_URL}/webapp/auth/login`, body, {
      withCredentials: true,
    });
    return res.data;
  } catch (e: any) {
    console.log(body, BASE_URL);
    console.log("[REQUEST] putMarkedNode", e.message);
  }
}

export async function requestSignup(body: { email: string; pwd: string }) {
  try {
    const res = await axios.post(`${BASE_URL}/webapp/auth/register`, body, {
      withCredentials: true,
    });
    return res.data;
  } catch (e: any) {
    console.log("[REQUEST] putMarkedNode", e.message);
  }
}

export async function requestProfilCreation(body: {
  age: string;
  name: string;
  avatar: string;
}) {
  try {
    const res = await axios.post(
      `${BASE_URL}/webapp/auth/registerProfile`,
      body,
      {
        withCredentials: true,
      }
    );
    return res.data;
  } catch (e: any) {
    console.log("[REQUEST] putMarkedNode", e.message);
  }
}

export async function getURLBeneylu(idSerie: string) {
  try {
    const response = await axios.get(
      `${BASE_URL}/webapp/auth/beneylu/login?serie=${idSerie}`,
      {
        withCredentials: true,
      }
    );

    const data = response.data;
    return data;
  } catch (e) {
    console.log("🚀 ~ file: request.ts:286 ~ getURLBeneylu ~ e:", e);
  }
}

export async function getTokenBeneylu(code: string, idSerie: string) {
  try {
    const response = await axios.get(
      `${BASE_URL}/webapp/auth/beneylu/token?code=${code}&serie=${idSerie}`,
      {
        withCredentials: true,
      }
    );

    const data = response.data;
    return data;
  } catch (e) {
    console.log("🚀 ~ file: request.ts:302 ~ getTokenBeneylu ~ e:", e);
  }
}

export async function loadBenTest() {
  try {
    const response = await axios.get(`${BASE_URL}/webapp/auth/beneylu/test`, {
      withCredentials: true,
    });

    const data = response.data;
    return data;
  } catch (e) {
    console.log("🚀 ~ file: request.ts:302 ~ loadBenTest ~ e:", e);
  }
}
