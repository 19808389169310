import "./App.css";
import Signin from "./signin/screen/Signin.tsx";

import "./fonts/LuckiestGuy-Regular.ttf";
import "./fonts/OpenDyslexic-Bold.ttf";
import "./fonts/OpenDyslexic-Italic.ttf";
import "./fonts/OpenDyslexic-Regular.ttf";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Poppins-Italic.ttf";
import "./fonts/Poppins-SemiBold.ttf";
import "./fonts/Roboto.ttf";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

function App() {
  return <Signin />;
}

export default App;
