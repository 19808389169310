import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import TagManager from "react-gtm-module";

import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import Signup from "./signup/screen/Signup";
import Signin from "./signin/screen/Signin";
import CreateProfil from "./signup/screen/CreateProfil";
import BeneyluLogin from "./beneylu/BeneyluLogin";

const tagManagerArgs = {
  gtmId: "GTM-N7DHCVT",
};

TagManager.initialize(tagManagerArgs);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/signin",
    element: <Signin />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/signup",
    element: <Signup />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/profil",
    element: <CreateProfil />,
    // errorElement: <ErrorPage />,
  },
  { path: "/beneylu/:idSerie", element: <BeneyluLogin /> },
  // {
  //   path: "/serie/:idSerie",
  //   element: <PageSerie />,
  // },
  // {
  //   path: "/reader/:idEpisode/:nodeId",
  //   element: <Reader />,
  // },
  // {
  //   path: "/profil",
  //   element: <Profil />,
  // },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
