import React from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Typography } from "antd";

const { Title } = Typography;

type Props = {
  errors: string | null;
  isLoading: boolean;
  trySignin: boolean;

  goToSignin(): void;
  onFinish(values: any): void;
};

const SignupVisual = (props: Props) => {
  const { errors, isLoading, trySignin, goToSignin, onFinish } = props;

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
        maxWidth: "100%",
      }}
    >
      <div
        className="containerSign"
        style={{
          backgroundColor: "white",
          // padding: "30px 68px",
          borderRadius: 10,
          minWidth: "25%",
        }}
      >
        <Title level={2} style={{ marginBottom: 28 }}>
          S'inscrire
        </Title>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Veuillez entrer un mail" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Mail"
              type="email"
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="pwd"
            rules={[
              { required: true, message: "Veuillez entrer un mot de passe" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mot de passe"
              size="large"
            />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ backgroundColor: "#FF6600" }}
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              S'inscrire
            </Button>
          </Form.Item>
        </Form>

        <div
          style={{
            marginTop: 10,
          }}
        >
          {errors ? (
            <Alert
              message={errors}
              action={
                trySignin ? (
                  <Button onClick={goToSignin} size="small" type="primary">
                    Se connecter
                  </Button>
                ) : null
              }
              type="error"
            />
          ) : null}
        </div>
        <div
          style={{
            marginTop: 40,

            flexDirection: "column",
            display: "flex",
          }}
        >
          <Button onClick={goToSignin} type="text" style={{ marginBottom: 30 }}>
            Vous avez un compte Nabook ?{<br />}Connectez-vous.
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignupVisual;
