import React, { useState } from "react";

import DefaultLayout from "../../shared/components/DefaultLayout";
import SigninVisual from "../components/SigninVisual";
import { requestSignin } from "../../shared/api/requests";
import { useNavigate, useSearchParams } from "react-router-dom";

const Signin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onFinish = async (values: any) => {
    setLoading(true);
    setErrors(null);
    const res = await requestSignin(values);

    if (!res.isOk) {
      setLoading(false);
      setErrors(res.errors);
    } else if (res.mustCreateProfil)
      navigate(`/profil?${searchParams.toString()}`, {
        replace: true,
        state: { mail: values.email },
      });
    else {
      const redirectTo = searchParams.get("redirect_to");
      searchParams.delete("redirect_to");

      if (redirectTo) {
        const redirectToParams = new URL(redirectTo).searchParams;

        if (redirectToParams.size > 0)
          window.location.replace(
            `${redirectTo}&prefilled_email=${values.email}${
              searchParams.size > 0 ? `&${searchParams.toString()}` : ""
            }`
          );
        else
          window.location.replace(
            `${redirectTo}?prefilled_email=${
              values.email
            }&${searchParams.toString()}`
          );
      } else window.location.replace("https://webapp.nabook.co");
      setLoading(false);
    }
  };

  const goToSignup = () => {
    navigate(`/signup?${searchParams.toString()}`);
  };

  return (
    <DefaultLayout>
      <SigninVisual
        goToSignup={goToSignup}
        errors={errors}
        isLoading={isLoading}
        onFinish={onFinish}
      />
    </DefaultLayout>
  );
};

export default Signin;
