import React, { ReactNode } from "react";

const background = require("../../assets/key-visual.png");

const DefaultLayout = (props: { children: ReactNode }) => {
  const { children } = props;
  return (
    <div
      style={{
        backgroundColor: "#120d35",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          display: "flex",
          height: "100%",
          minHeight: "100vh",
          overflow: "hidden",
          position: "absolute",
          zIndex: 0,
          justifyContent: "end",
          right: -220,
          WebkitTransform: "rotateX(22deg) rotateY(-12deg)",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: "rgba(18, 13, 53,0.5)",
          }}
        />
        <img
          style={{
            height: "100%",
            aspectRatio: 1693 / 982,
          }}
          src={background}
          alt=""
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
          padding: 20,
        }}
      >
        <div
          style={{
            width: "100%",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <a href="https://www.nabook.co">
            <img
              src={require("../../assets/logo-header.png")}
              style={{
                width: "14vw",
                aspectRatio: 342 / 105,
              }}
              alt=""
            />
          </a>
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flex: 1,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
