import React from "react";
import { Alert, Button, Form, Input, Radio, Slider, Typography } from "antd";

import "../css/CreateProfil.css";

const { Title } = Typography;

type Props = {
  errors: string | null;
  isLoading: boolean;

  onFinish(values: any): void;
};

const avatars = [
  {
    img: require("../../assets/avatars/base_v2_alibaba.png"),
    name: "base_v2_alibaba.png",
  },
  {
    img: require("../../assets/avatars/base_v2_lulu.png"),
    name: "base_v2_lulu.png",
  },
  {
    img: require("../../assets/avatars/base_v2_zombie.png"),
    name: "base_v2_zombie.png",
  },
  {
    img: require("../../assets/avatars/base_v2_sonia.png"),
    name: "base_v2_sonia.png",
  },
  {
    img: require("../../assets/avatars/base_v2_robot.png"),
    name: "base_v2_robot.png",
  },
  {
    img: require("../../assets/avatars/base_v2_geante.png"),
    name: "base_v2_geante.png",
  },
  {
    img: require("../../assets/avatars/base_v2_genie.png"),
    name: "base_v2_genie.png",
  },
  {
    img: require("../../assets/avatars/base_v2_kroy.png"),
    name: "base_v2_kroy.png",
  },

  {
    img: require("../../assets/avatars/base_v2_marraine.png"),
    name: "base_v2_marraine.png",
  },
  {
    img: require("../../assets/avatars/base_v2_pelican.png"),
    name: "base_v2_pelican.png",
  },
  {
    img: require("../../assets/avatars/base_v2_souris.png"),
    name: "base_v2_souris.png",
  },

  {
    img: require("../../assets/avatars/base_v2_bijoutier.png"),
    name: "base_v2_bijoutier.png",
  },
];

const CreateProfilVisual = (props: Props) => {
  const { errors, isLoading, onFinish } = props;

  const renderAvatar = (img: string, name: string) => {
    return (
      <Radio.Button
        style={{
          width: 100,
          height: 100,
          margin: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: 0,
          borderColor: "transparent",
          borderRadius: 100,
        }}
        value={name}
        key={name}
      >
        <img style={{ width: 70, height: 70 }} src={img} alt="Logo" />
      </Radio.Button>
    );
  };

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flex: 1,
        maxWidth: "100%",
      }}
    >
      <div
        className="containerSign"
        style={{
          backgroundColor: "white",
          // padding: "30px 68px",
          borderRadius: 10,
          minWidth: "25%",
        }}
      >
        <Title level={2} style={{ marginBottom: 28 }}>
          Créer le profil du lecteur
        </Title>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{ avatar: "base_v2_alibaba.png", age: 10 }}
        >
          <Form.Item
            label="Prénom"
            name="name"
            rules={[
              {
                required: true,
                message: "Veuillez entrer un nom d'utilisateur",
              },
            ]}
          >
            <Input placeholder="Prénom enfant" size="large" />
          </Form.Item>

          <Form.Item name="age" label="Âge">
            <Slider
              marks={{
                7: "7",
                8: "8",
                9: "9",
                10: "10",
                11: "11",
                12: "12",
                13: "13",
                14: "14",
              }}
              min={7}
              max={14}
              step={1}
            />
          </Form.Item>

          <Form.Item
            name="avatar"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.06)",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <Radio.Group
              style={{
                display: "flex",
                overflow: "scroll",
                maxWidth: 450,
              }}
              buttonStyle="solid"
            >
              {avatars.map((a) => renderAvatar(a.img, a.name))}
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ backgroundColor: "#FF6600" }}
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Enregistrer
            </Button>
          </Form.Item>
        </Form>

        <div
          style={{
            marginTop: 10,
          }}
        >
          {errors ? <Alert message={errors} type="error" /> : null}
        </div>
      </div>
    </div>
  );
};

export default CreateProfilVisual;
