import React, { useState } from "react";

import DefaultLayout from "../../shared/components/DefaultLayout";
import CreateProfilVisual from "../components/CreateProfilVisual";
import { requestProfilCreation } from "../../shared/api/requests";
import { useLocation, useSearchParams } from "react-router-dom";

const CreateProfil = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onFinish = async (values: any) => {
    setLoading(true);
    setErrors(null);
    const res = await requestProfilCreation(values);

    if (res && !res.isOk) {
      setLoading(false);
      setErrors(res.errors);
      return;
    }

    const redirectTo = searchParams.get("redirect_to");
    searchParams.delete("redirect_to");

    if (redirectTo) {
      const redirectToParams = new URL(redirectTo).searchParams;
      const prefilledEmail =
        location.state && location.state.mail
          ? `prefilled_email=${location.state.mail}`
          : "";
      const joiner = [prefilledEmail, searchParams.toString()]
        .filter((s) => s.length > 0)
        .join("&");

      if (redirectToParams.size > 0)
        window.location.replace(
          `${redirectTo}${joiner.length > 0 ? `&${joiner}` : ""}`
        );
      else
        window.location.replace(
          `${redirectTo}${joiner.length > 0 ? `?${joiner}` : ""}`
        );
    } else window.location.replace("https://webapp.nabook.co");

    setLoading(false);
  };

  return (
    <DefaultLayout>
      <CreateProfilVisual
        errors={errors}
        isLoading={isLoading}
        onFinish={onFinish}
      />
    </DefaultLayout>
  );
};

export default CreateProfil;
