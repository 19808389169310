import React, { useCallback, useEffect } from "react";
import { getTokenBeneylu, getURLBeneylu } from "../shared/api/requests";
import { useParams, useSearchParams } from "react-router-dom";

const BeneyluLogin = () => {
  const [searchParams] = useSearchParams();
  const { idSerie } = useParams();

  const onClick = useCallback(async () => {
    const data = await getURLBeneylu(idSerie || "");
    console.log("🚀 ~ file: login.tsx:8 ~ onClick ~ data:", data);
    window.location.assign(data.url);
  }, [idSerie]);

  const loadToken = useCallback(
    async (code: string) => {
      const q = await getTokenBeneylu(code, idSerie || "");
      console.log("🚀 ~ file: login.tsx:17 ~ loadToken ~ q:", q);
      if (idSerie !== null)
        window.location.assign("https://webapp.nabook.co/serie/" + idSerie);
    },
    [idSerie]
  );

  useEffect(() => {
    const paramCode = searchParams.get("code");
    if (paramCode) {
      console.log(
        "🚀 ~ file: login.tsx:22 ~ useEffect ~ paramCode:",
        paramCode
      );
      loadToken(paramCode);
    } else onClick();
  }, [loadToken, onClick, searchParams]);

  return <div>{/* <button onClick={onClick}>Login</button> */}</div>;
};

export default BeneyluLogin;
